import cx from 'classnames';
import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';
import {
  FunctionComponent,
  useState,
  memo,
  useEffect,
  useRef,
  useMemo,
} from 'react';
import {
  Carousel,
  CarouselBullets,
  CarouselContent,
  CarouselItem,
} from '../../ui/carousel-v2';

import { useResolveLink } from '../../../hooks/use-link-props';
import useIsMobile from '../../../hooks/use-is-mobile';
import BPImage from '../../ui/v2/image';

const TopBrands: FunctionComponent<{ brands: Array<TBrand> }> = ({
  brands,
}) => {
  const ref = useRef<HTMLElement>(null);
  const [shouldGetImages, setShouldGetImages] = useState(false);
  const [page, setPage] = useState(0);
  const { t } = useTranslation();
  const getLink = useResolveLink();
  const isMobile = useIsMobile();

  useEffect(() => {
    const watcher: IntersectionObserverCallback = (entries) => {
      if (entries[0].intersectionRatio !== 0) {
        setShouldGetImages(true);
      }
    };

    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0,
    };

    let observer: IntersectionObserver;

    if (typeof IntersectionObserver !== 'undefined') {
      observer = new IntersectionObserver(watcher, options);

      ref.current && observer.observe(ref.current);
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, []);

  const { brandPages, brandsToDisplay } = useMemo(() => {
    const brandsToDisplay = brands.filter((b) => b.is_top_brand);
    const pages = Math.ceil(brandsToDisplay.length / 8);

    return {
      brandPages: Array(pages)
        .fill(0)
        .map((v, i) => i),
      brandsToDisplay,
    };
  }, [brands]);

  return (
    <section className={cx('text-center')} ref={ref}>
      <div className={cx('')}>
        <div className="pt-9 lg:pb-0 container m-auto lg:pt-12 max-lg:px-4">
          <h2 className="text-2xl font-bold lg:px-6">
            {t('home:top-brands.title')}
          </h2>
          <p className="text-base lg:px-6 max-lg:pb-3 pb-[64px]">
            {t('home:top-brands.sub-title')}
          </p>
          <div className="">
            <Carousel
              opts={{
                align: 'center',
              }}
              viewportBasedSlidesToScroll={{
                desktop: 4,
                mobile: 3,
              }}
              className=""
              autoplay
            >
              <CarouselContent className="lg:-ml-6">
                {brandsToDisplay
                  .reduce(
                    (final, item) => {
                      const last = final.length > 0 ? final.length - 1 : 0;
                      if (final[last].length < 2) {
                        final[last].push(item);
                      } else {
                        final.push([item]);
                      }
                      return final;
                    },
                    [[]]
                  )
                  .map((item, index) => {
                    return (
                      <CarouselItem
                        key={`img_${index}`}
                        className="pl-0 basis-1/3 lg:basis-1/4 lg:pl-6 lg:grid lg:grid-cols-2 lg:gap-6 max-lg:h-fit"
                      >
                        {item.map((i, index_) => {
                          return (
                            <Link
                              key={index_}
                              href={`/auto-parts/search?vehicle=${i.slug}`}
                              className={cx(
                                'flex justify-center items-center',
                                'h-full w-full mx-auto',
                                'h-auto w-full aspect-square',
                                'hover:bg-brand-10'
                              )}
                            >
                              <BPImage
                                src={`${process.env.STATIC_PREFIX}/brands/logos/${i.alias}.svg`}
                                alt={i.name}
                                width={300}
                                height={300}
                                className={cx('w-[60px] h-auto', 'lg:w-[72px]')}
                              />
                            </Link>
                          );
                        })}
                      </CarouselItem>
                    );
                  })}
              </CarouselContent>
              <CarouselBullets />
            </Carousel>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TopBrands;
